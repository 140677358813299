import { Button, Table, Tag, PageHeader, Form, Input, Modal, Select, Typography } from "antd";
import { useRequest, useToggle } from "ahooks";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/file";

import { getCameras, getCameraById, createCamera, updateCamera } from "@/api/cameras";
import Loader from "@/components/UI/Loader";

const { Column } = Table;
const { Text } = Typography;

const states = [
  {
    state: "active",
    name: "активна",
    style: "success"
  },
  {
    state: "disabled",
    name: "отключена",
    style: "error"
  }
];

export default function CamerasPage() {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedUrl, setSelectedUrl] = useState<string>("");
  const [previewError, setPreviewError] = useState<string>("");
  const [isModal, { setLeft: setIsModalLeft, setRight: setIsModalRight }] = useToggle<boolean>(false);
  const [isPreview, { setLeft: setIsPreviewLeft, setRight: setIsPreviewRight }] = useToggle<boolean>(false);
  const [isPreviewLoading, {
    setLeft: setIsPreviewLoadingLeft,
    setRight: setIsPreviewLoadingRight
  }] = useToggle<boolean>(false);
  const [form] = Form.useForm();

  const { loading, data, run } = useRequest(getCameras);

  const { loading: isDetailsLoading, data: details } = useRequest(() => getCameraById(selectedId), {
    refreshDeps: [selectedId],
    ready: !!selectedId
  });

  useEffect(() => {
    if (selectedId === details?.id) {
      form.setFieldValue("name", details?.name);
      form.setFieldValue("description", details?.description);
      form.setFieldValue("url", details?.url);
      form.setFieldValue("location_x", details?.location?.x);
      form.setFieldValue("location_y", details?.location?.y);
      form.setFieldValue("state", details?.state);
    }
  }, [form, details, selectedId]);

  function handleCreate() {
    setIsModalRight();
    form.resetFields();
    setSelectedId(0);
  }

  function handleCancel() {
    setIsModalLeft();
    setSelectedId(0);
  }

  function handleEdit(e: any, record: any) {
    e.stopPropagation();
    setSelectedId(record?.id);
    setIsModalRight();
  }

  function getCameraState(state: string) {
    return states.find((o: any) => o.state === state);
  }

  function handleSubmit(values: any) {
    const { name, description, url, state, location_x, location_y } = values;

    const requestData = {
      name,
      description,
      url,
      state,
      location: [location_x, location_y]
    };

    if (!!selectedId) {
      updateCamera(selectedId, requestData)
        .finally(() => {
          setIsModalLeft();
          form.resetFields();
          run();
        });
    } else {
      createCamera(requestData)
        .finally(() => {
          setIsModalLeft();
          form.resetFields();
          run();
        });
    }
  }

  function handlePreviewClose() {
    setIsPreviewLeft();
  }

  function handlePreviewError(error: string) {
    setPreviewError(error);
  }

  return (
    <div className="container">
      <PageHeader title="Камеры"
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0
                  }}
                  extra={[
                    <Button type="primary" key={1} onClick={handleCreate}>Создать новую камеру</Button>
                  ]}
      />
      <Table dataSource={data}
             rowKey={"id"}
             loading={loading}
             pagination={false}
             className={"mb-16"}
             scroll={{ x: 1000 }}
             onRow={(record) => {
               return {
                 onClick: () => {
                   setSelectedUrl(record?.url);
                   setPreviewError("");
                   setIsPreviewLoadingRight();
                   setIsPreviewRight();
                 }
               };
             }}
      >
        <Column title="Название" dataIndex="name" key="id" width={100} />
        <Column title="Id" dataIndex="id" key="id" align={"center"} width={50}/>
        <Column title="Описание" dataIndex="description" key="id" />
        <Column title="Ссылка" dataIndex="url" key="id" />
        <Column title="Локация" dataIndex="location" key="id"
                render={(location) => `x:${location?.x}, y:${location?.y}`} />
        <Column title="Состояние" dataIndex="state" key="id" render={(state) => {
          const stateObj = getCameraState(state);

          return <Tag color={stateObj?.style}>{stateObj?.name}</Tag>;
        }} />
        <Column
          title="Действия"
          key="id"
          align={"center"}
          render={(_, record) => (
            <Button type="link" onClick={(e) => handleEdit(e, record)}>Изменить</Button>
          )}
        />
      </Table>

      <Modal
        open={isModal}
        title={!!selectedId ? "Изменить камеру" : "Создать новую камеру"}
        okText={!!selectedId ? "Сохранить" : "Создать"}
        cancelText="Отмена"
        onCancel={handleCancel}
        onOk={() => form.submit()}
      >
        {
          isDetailsLoading && <Loader />
        }
        {
          !isDetailsLoading &&
          (
            <Form
              form={form}
              layout="vertical"
              name="categoryCreateEdit"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="name"
                label="Название"
                rules={[{ required: true, message: "Введите название" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="description"
                label="Описание"
                rules={[{ required: true, message: "Введите описание" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="url"
                label="Ссылка"
                rules={[{ required: true, message: "Введите ссылку на видео" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="location_x"
                label="Локация, x"
                rules={[{ required: true, message: "Введите координату x" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="location_y"
                label="Локация, y"
                rules={[{ required: true, message: "Введите координату y" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="state"
                label="Состояние"
                rules={[{ required: true, message: "Выберите состояние" }]}
              >
                <Select options={states.map((s: any) => ({
                  label: (
                    <Tag color={s.style}>{s.name}</Tag>
                  ),
                  value: s.state
                }))} />
              </Form.Item>
            </Form>
          )
        }
      </Modal>

      <Modal
        open={isPreview}
        closable={false}
        footer={[
          <Button key="close" onClick={handlePreviewClose}>
            Закрыть
          </Button>
        ]}
        bodyStyle={{
          minHeight: 0
        }}
      >
        <ReactPlayer
          url={selectedUrl}
          width={isPreviewLoading ? 0 : "100%"}
          height={isPreviewLoading ? 0 : "100%"}
          muted
          playsinline
          playing
          config={{
            forceHLS: true
          }}
          onError={handlePreviewError}
          onBufferEnd={setIsPreviewLoadingLeft}
        />
        {
          isPreviewLoading &&
          <Loader />
        }
        <Text type="danger">{previewError}</Text>
      </Modal>
    </div>
  );
}