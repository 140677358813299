import { Button, Table, PageHeader, Modal, Form, Input } from "antd";
import { useRequest, useToggle } from "ahooks";
import { useEffect, useState } from "react";

import { createCategory, getCategories, getCategoryById, updateCategory } from "@/api/categories";
import { getLocaleDate, saveCategories } from "@/helpers";
import Loader from "@/components/UI/Loader";

const { Column } = Table;

export default function CategoriesPage() {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [isModal, { setLeft: setIsModalLeft, setRight: setIsModalRight }] = useToggle<boolean>(false);
  const [form] = Form.useForm();

  const { loading, data, run } = useRequest(getCategories);

  const { loading: isDetailsLoading, data: details, run: handleChangeCategory } = useRequest(() => getCategoryById(selectedId), {
    ready: !!selectedId,
  })

  useEffect(() => {
    form.setFieldValue("name", details?.name)
  }, [form, details])

  function handleCreate() {
    setIsModalRight();
    form.resetFields();
    setSelectedId(0);
  }

  function handleCancel() {
    setIsModalLeft();
    setSelectedId(0);
  }

  function handleSubmit(values: any) {
    const { name } = values;

    if (!!selectedId) {
      updateCategory(selectedId, { name })
        .finally(() => {
          setIsModalLeft();
          form.resetFields();
          run();
          saveCategories();
        });
    } else {
      createCategory({ name })
        .finally(() => {
          setIsModalLeft();
          form.resetFields();
          run();
          saveCategories();
        });
    }
  }

  function handleEdit(record: any) {
    setSelectedId(record?.id)
    handleChangeCategory();
    setIsModalRight();
  }

  return (
    <div className="container">
      <PageHeader title="Категории"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }}
        extra={[
          <Button type="primary" key={1} onClick={handleCreate}>Создать новую категорию</Button>
        ]}
      />
      <Table dataSource={data}
        rowKey={"id"}
        loading={loading}
        pagination={false}
        className={"mb-16"}
        scroll={{ x: 600 }}
      >
        <Column title="Название категории" dataIndex="name" key="id" width={100} />
        <Column title="id" dataIndex="id" key="id" align={"right"} width={50} />
        <Column title="Дата создания" dataIndex="created_at" key="id" render={(created_at: string) => getLocaleDate(created_at)} />
        <Column title="Дата обновления" dataIndex="updated_at" key="id" render={(updated_at: string) => getLocaleDate(updated_at)} />
        <Column
          title="Действия"
          key="id"
          align={"center"}
          render={(_, record) => (
            <Button type="link" onClick={() => handleEdit(record)}>Изменить</Button>
          )}
        />
      </Table>

      <Modal
        open={isModal}
        title={!!selectedId ? "Изменить категорию" : "Создать новую категорию"}
        okText={!!selectedId ? "Сохранить" : "Создать"}
        cancelText="Отмена"
        onCancel={handleCancel}
        onOk={() => form.submit()}
      >
        {
          isDetailsLoading && <Loader />
        }
        {
          !isDetailsLoading &&
          (
            <Form
              form={form}
              layout="vertical"
              name="categoryCreateEdit"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="name"
                label="Название категории"
                rules={[{ required: true, message: "Введите название категории" }]}
              >
                <Input />
              </Form.Item>
            </Form>
          )
        }
      </Modal>
    </div>
  );
}