import React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale-provider/ru_RU";

import "./App.less";
import useAuth, { AuthProvider } from "@/hooks/useAuth";
import LoginPage from "@/components/pages/LoginPage";
import Layout from "@/components/UI/Layout";
import MainPage from "@/components/pages/MainPage";
import CreateEditOrderPage from "@/components/pages/CreateEditOrderPage";
import OrderPage from "@/components/pages/OrderPage";
import CategoriesPage from "@/components/pages/CategoriesPage";
import CamerasPage from "@/components/pages/CamerasPage";
import UsersPage from "@/components/pages/UsersPage";

export default function App() {
  return (
    <ConfigProvider locale={ruRU}>
      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/"
              element={
                <RequireAuth>
                  <MainPage />
                </RequireAuth>
              }
            />
            <Route path="/order/create"
              element={
                <RequireAuth>
                  <CreateEditOrderPage />
                </RequireAuth>
              }
            />
            <Route path="/order/edit/:id"
              element={
                <RequireAuth>
                  <CreateEditOrderPage />
                </RequireAuth>
              }
            />
            <Route path="/order/:id"
              element={
                <RequireAuth>
                  <OrderPage />
                </RequireAuth>
              }
            />
            <Route path="/category"
              element={
                <RequireAuth>
                  <CategoriesPage />
                </RequireAuth>
              }
            />
            <Route path="/camera"
              element={
                <RequireAuth>
                  <CamerasPage />
                </RequireAuth>
              }
            />
            <Route path="/user"
              element={
                <RequireAuth>
                  <UsersPage />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </ConfigProvider>

  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  if (!auth.isAuth && !auth.isLoading) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
