import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, Form, Typography } from "antd";

import useAuth from "@/hooks/useAuth";
import { saveCategories } from "@/helpers";

const { Title, Text } = Typography;

export default function LoginPage() {
  let navigate = useNavigate();
  let { signin } = useAuth();
  const [error, setError] = useState<string>("");

  function handleSubmit(values: any) {
    setError("");

    const { login, password } = values;

    signin({ login, password }, () => {
      saveCategories();
      navigate("/", { replace: true });
    },
      () => {
        setError("Неверный логин или пароль");
      });
  }

  return (
    <div className="container container_center">
      <div className="login-form">
        <Title level={2} className="text-center" style={{
          fontSize: 24,
          lineHeight: "32.68px",
          marginBottom: 48
        }}>АСУ Лотос</Title>
        <Form name="login"
          style={{
            display: "flex",
            flexDirection: "column"
          }}
          initialValues={{
            remember: true
          }}
          onFinish={handleSubmit}
          autoComplete="off">
          <Form.Item name="login"
            className="mb-10"
            rules={[
              {
                required: true,
                message: "Введите номер телефона"
              }
            ]}>
            <Input type="tel" placeholder="Телефон" />
          </Form.Item>

          <Form.Item name="password"
            className="mb-10"
            rules={[
              {
                required: true,
                message: "Введите пароль"
              }
            ]}>
            <Input.Password type="password" placeholder="Пароль" />
          </Form.Item>
          {!!error && <Text type="danger">{error}</Text>}
          <Button type="link"
            style={{
              padding: 0,
              marginBottom: 30,
              alignSelf: "flex-end"
            }}>Забыли пароль?</Button>
          <Button type="primary" htmlType="submit" block>Войти</Button>
        </Form>
      </div>
    </div>
  );
}
