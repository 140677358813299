import { Button, Table, Tag, PageHeader, Form, Input, Modal, Select, AutoComplete, Checkbox } from "antd";
import { useRequest, useToggle } from "ahooks";
import React, { useEffect, useState } from "react";

import { getUsers, getUserById, createUser, updateUser } from "@/api/users";
import { getAddress, getFio } from "@/api/suggestions";
import Loader from "@/components/UI/Loader";

const { Column } = Table;

const states = [
  {
    state: "active",
    name: "активен",
    style: "success"
  },
  {
    state: "disabled",
    name: "отключен",
    style: "error"
  }
];

const roles = [
  "user", "manager", "admin", "servant"
];

export default function UsersPage() {
  const [selectedId, setSelectedId] = useState<number>(0);
  const [nameOptions, setNameOptions] = useState<any[]>([]);
  const [addressOptions, setAddressOptions] = useState<any[]>([]);

  const [isModal, { setLeft: setIsModalLeft, setRight: setIsModalRight }] = useToggle<boolean>(false);
  const [form] = Form.useForm();

  const { loading, data, run } = useRequest(getUsers);

  const categories = JSON.parse(sessionStorage.getItem("categories") || "[]")
    .map((c: any) => ({
      label: c.name,
      value: c.id
    }));

  const { loading: isDetailsLoading, data: details, run: getUser } = useRequest(() => getUserById(selectedId), {
    ready: !!selectedId,
  });

  function onNameSearch(query: string) {
    getFio(query).then((data) => {
      setNameOptions(data);
    });
  }

  function onAddressSearch(query: string) {
    getAddress(query).then((data) => {
      setAddressOptions(data);
    });
  }

  useEffect(() => {
    if (selectedId === details?.id) {
      form.setFieldValue("phone", details?.phone);
      form.setFieldValue("name", details?.name);
      form.setFieldValue("email", details?.email);
      form.setFieldValue("state", details?.state);
      form.setFieldValue("address", details?.address);
      form.setFieldValue("roles", details?.roles);
      form.setFieldValue("order_category_ids", details?.order_category_ids);
    }
  }, [form, details, selectedId]);

  function getUserState(state: string) {
    return states.find((o: any) => o.state === state);
  }

  function handleCreate() {
    setIsModalRight();
    form.resetFields();
    setSelectedId(0);
  }

  function handleCancel() {
    setIsModalLeft();
    setSelectedId(0);
  }

  function handleEdit(e: any, record: any) {
    e.stopPropagation();
    setSelectedId(record?.id);
    getUser();
    setTimeout(setIsModalRight, 200);
  }

  function handleSubmit(values: any) {
    const { phone, password, email, state, address, roles, order_category_ids, name } = values;

    const requestData = {
      phone,
      password,
      email,
      name,
      state,
      address,
      roles,
      order_category_ids
    };

    if (!!selectedId) {
      updateUser(selectedId, requestData)
        .finally(() => {
          setIsModalLeft();
          form.resetFields();
          run();
        });
    } else {
      createUser(requestData)
        .finally(() => {
          setIsModalLeft();
          form.resetFields();
          run();
        });
    }
  }

  return (
    <div className="container">
      <PageHeader title="Пользователи"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }}
        extra={[
          <Button type="primary" key={1} onClick={handleCreate}>Создать нового пользователя</Button>
        ]}
      />
      <Table dataSource={data}
        rowKey={"id"}
        loading={loading}
        pagination={false}
        className={"mb-16"}
        scroll={{ x: 1000 }}
      >
        <Column title="Логин" dataIndex="phone" key="id" width={100} />
        <Column title="Id" dataIndex="id" key="id" align={"right"} width={50} />
        <Column title="Email" dataIndex="email" key="id" />
        <Column title="Роли" dataIndex="roles" key="id" render={(roles) => roles.join(", ")} />
        <Column title="Категории" dataIndex="order_category_ids" key="id" render={(order_category_ids: number[]) => order_category_ids ?
          order_category_ids.map((id: number) =>
            categories.find((cat: any) => cat.value === id)?.label
          ).join(", ") : ""} />
        <Column title="Состояние" dataIndex="state" key="id" render={(state) => {
          const stateObj = getUserState(state);

          return <Tag color={stateObj?.style}>{stateObj?.name}</Tag>;
        }} />
        <Column
          title="Действия"
          key="id"
          align={"center"}
          render={(_, record) => (
            <Button type="link" onClick={(e) => handleEdit(e, record)}>Изменить</Button>
          )}
        />
      </Table>

      <Modal
        open={isModal}
        title={!!selectedId ? "Изменить пользователя" : "Создать нового пользователя"}
        okText={!!selectedId ? "Сохранить" : "Создать"}
        cancelText="Отмена"
        onCancel={handleCancel}
        onOk={() => form.submit()}
      >
        {
          isDetailsLoading && <Loader />
        }
        {
          !isDetailsLoading &&
          (
            <Form
              form={form}
              layout="vertical"
              name="categoryCreateEdit"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="phone"
                label="Логин"
                rules={[{ required: true, message: "Введите логин" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Пароль"
                rules={[{ required: !selectedId, message: "Введите пароль" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="name"
                label="ФИО"
                labelCol={{
                  xs: 24,
                  md: 4,
                }}
              >
                <AutoComplete
                  options={nameOptions}
                  placeholder={""}
                  onSearch={onNameSearch}
                />
              </Form.Item>
              <Form.Item
                name={["address", "value"]}
                label="Адрес"
                labelCol={{
                  xs: 24,
                  md: 4,
                }}
              >
                <AutoComplete
                  options={addressOptions}
                  placeholder={""}
                  onSearch={onAddressSearch}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Электронная почта"
                rules={[{ type: "email", required: true, message: "Введите email пользователя" }]}
              >
                <Input type="email" />
              </Form.Item>
              <Form.Item
                name="state"
                label="Состояние"
                rules={[{ required: true, message: "Выберите состояние" }]}
              >
                <Select options={states.map((s: any) => ({
                  label: (
                    <Tag color={s.style}>{s.name}</Tag>
                  ),
                  value: s.state
                }))} />
              </Form.Item>
              <Form.Item
                name="roles"
                label="Роли"
                rules={[{ required: true, message: "Выберите роли" }]}
              >
                <Checkbox.Group options={roles} />
              </Form.Item>
              <Form.Item
                name="order_category_ids"
                label="Категории"
                rules={[{ required: true, message: "Выберите категории" }]}
              >
                <Checkbox.Group options={categories} />
              </Form.Item>
            </Form>
          )
        }
      </Modal>

    </div>
  );
};
