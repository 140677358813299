import React, { useEffect, useState } from "react";
import { Button, Form, Input, PageHeader, Select, Row, Col, Image, AutoComplete, Tag, Radio, Upload } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import history from "history/browser";
// @ts-ignore
import InputMask from "react-input-mask";

import { createOrder, getOrderDetails, getUploadLink, updateOrder, uploadPhoto } from "@/api/order";
import { useRequest } from "ahooks";
import Loader from "@/components/UI/Loader";
import { getAddressValue, getNameValue } from "@/helpers";
import { getAddress, getFio } from "@/api/suggestions";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";

const items = [
  {
    value: "created",
    label: (
      <Tag color="processing">новое</Tag>
    )
  },
  {
    value: "waiting",
    label: (
      <Tag color="processing">в работу</Tag>
    )
  },
  {
    value: "urgent",
    label: (
      <Tag color="error">авария</Tag>
    )
  },
  {
    value: "completed",
    label: (
      <Tag color="success">выполнено</Tag>
    )
  },
  {
    value: "cancelled",
    label: (
      <Tag color="warning">отменено</Tag>
    )
  },
  {
    value: "rejected",
    label: (
      <Tag color="warning">отказано</Tag>
    )
  }
];

export default function CreateEditOrderPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const isEdit = !!params.id;

  const [addressOptions, setAddressOptions] = useState<any[]>([]);
  const [nameOptions, setNameOptions] = useState<any[]>([]);
  const [currentState, setCurrentState] = useState<string>("");

  const { loading, data } = useRequest(() => getOrderDetails(params.id), { ready: !!params?.id });

  const categories = JSON.parse(
    sessionStorage.getItem("categories") || "[]"
  ).map((c: any) => ({
    label: c.name,
    value: c.id
  }));

  function onAddressSearch(query: string) {
    getAddress(query).then((data) => {
      setAddressOptions(data);
    });
  }

  function onNameSearch(query: string) {
    getFio(query).then((data) => {
      setNameOptions(data);
    });
  }

  function handleCancel() {
    history.back();
  }

  function handleCreate() {
    navigate("/order/create");
  }

  function handleSubmit(values: any) {
    const {
      customer_name,
      address,
      customer_phone,
      order_category_id,
      text,
      state,
      photos,
      servant_id
    } = values;

    function sendRequest(photos: string[] = []) {
      const requestData = {
        text,
        order_category_id,
        customer_phone,
        state,
        servant_id,
        address: getAddressValue(address, addressOptions),
        customer_name: getNameValue(customer_name, nameOptions),
        photos: [
          ...(data?.photos?.length ? data.photos.map((photo: any) => photo) : []),
          ...photos.map(photo => photo),
        ]

      };
      if (isEdit && params?.id) {
        updateOrder(+params.id, requestData)
          .finally(() => {
            handleCancel();
          });
      } else {
        createOrder(requestData)
          .then((data) => {
            navigate(`/order/${data.id}`, { replace: true });
          });
      }
    }

    const guid = crypto.randomUUID
      ? crypto.randomUUID()
      : new Date().getTime().toString(16);

    if (photos && photos?.fileList?.length) {
      Promise.all(
        photos.fileList.map((f: any) => {
          const { name, type } = f;
          return getUploadLink(name, type, guid);
        })
      )
        .then((data: any[]) => {
          const sendPhotos: string[] = data.map((res: any) => res.signedUrl);
          return Promise.all(
            sendPhotos.map((url: string, index: number) => {
              const file = photos.fileList[index];
              return uploadPhoto(url, file.originFileObj, file.type);
            })
          ).then(() => {
            sendRequest(sendPhotos);

          });
        })
    } else {
      sendRequest();
    }
  }


  const allowedServants = data?.allowed_servants.map(({ id, name }: { id: number, name: { raw: string } }) => {
    return {
      value: id,
      label: name?.raw || name,
      id
    }
  })

  useEffect(() => {
    if (data?.state) {
      setCurrentState(data?.state)
    }
  }, [data?.state])

  return (
    <div className="container">
      {
        isEdit &&
        <PageHeader title={`Заявка #${params.id}`}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0
          }}
          extra={[
            <Button type="primary" key={1} onClick={handleCreate}>Создать новую заявку</Button>
          ]} />
      }
      <div className="order-card">
        {
          loading && <Loader />
        }
        {
          !loading && (
            <>
              <PageHeader title={isEdit ? (data.address?.raw || data.address?.value?.value) : "Создать заявку"}
                onBack={handleCancel}
              />
              <Row className="order-card__body">
                <Col lg={8} md={12} xs={24}>
                  <Form name="order"
                    form={form}
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                    onFinish={handleSubmit}
                    autoComplete="off">
                    <Form.Item name="order_category_id"
                      required
                      label="Категория"
                      labelCol={{ span: 24 }}
                      initialValue={isEdit ? data.order_category_id : ""}
                      rules={[
                        {
                          required: true,
                          message: "Выберите категорию"
                        }
                      ]}>
                      <Radio.Group>
                        {
                          categories.map((c: any) => (
                            <Radio value={c.value} key={c.value}>{c.label}</Radio>
                          ))
                        }
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name="address"
                      required
                      label="Адрес"
                      labelCol={{ span: 24 }}
                      initialValue={isEdit ? data.address?.value : ""}
                      rules={[
                        {
                          required: true,
                          message: "Введите адрес"
                        }
                      ]}>
                      <AutoComplete
                        options={addressOptions}
                        placeholder={"Адрес подачи заявки"}
                        onSearch={onAddressSearch}
                      />
                    </Form.Item>
                    <Form.Item name="customer_name"
                      required
                      label="Контактное лицо"
                      labelCol={{ span: 24 }}
                      initialValue={isEdit ? (data.customer_name?.raw || data.customer_name?.value) : ""}
                      rules={[
                        {
                          required: true,
                          message: "Введите имя контактного лица"
                        },
                        {
                          pattern: /^(\D*)$/g,
                          message: "Введите имя контактного лица"
                        }
                      ]}>
                      <AutoComplete
                        options={nameOptions}
                        placeholder={"Контактное лицо"}
                        onSearch={onNameSearch}
                      />
                    </Form.Item>
                    <Form.Item name="customer_phone"
                      required
                      label="Телефон контактного лица"
                      labelCol={{ span: 24 }}
                      initialValue={isEdit ? data.customer_phone : ""}
                      rules={[
                        {
                          required: true,
                          message: "Введите телефон"
                        },
                        {
                          pattern: /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/g,
                          message: "Неверный номер телефона"
                        }
                      ]}>
                      <InputMask
                        className="ant-input phone"
                        type="tel"
                        alwaysShowMask
                        mask={"+7(999)999-99-99"}
                        placeholder="Номер телефона" />
                    </Form.Item>
                    <Form.Item name="text"
                      initialValue={isEdit ? data.text : ""}
                      labelCol={{ span: 24 }}
                      label="Заявка">
                      <Input.TextArea placeholder="Опишите проблему" />
                    </Form.Item>
                    <Form.Item name="photos" valuePropName={"file"}>
                      <Upload
                        listType="picture-card"
                        accept="capture=camera,image/*"
                        beforeUpload={() => false}
                        showUploadList={{
                          showPreviewIcon: false,
                          removeIcon: (
                            <CloseCircleFilled style={{ color: "#1890FF", fontSize: 24 }} />
                          ),
                        }}
                      >
                        <PlusOutlined style={{ fontSize: 32 }} />
                      </Upload>
                    </Form.Item>
                    {
                      isEdit && !!data.photos?.length &&
                      <Row gutter={[8, 8]} align={"stretch"} className="mb-16">
                        <Image.PreviewGroup>
                          {
                            data.photos?.map((p: string) => (
                              <Col span={6} key={p}>
                                <Image
                                  src={p}
                                  className="img-cover"
                                  rootClassName="image-wrapper"
                                />
                              </Col>
                            ))
                          }
                        </Image.PreviewGroup>
                      </Row>
                    }
                    {
                      isEdit &&
                      <Form.Item name="state"
                        required
                        initialValue={isEdit ? data.state : ""}
                        label="Статус"
                        labelCol={{ span: 24 }}>
                        <Select
                          options={items}
                          onChange={setCurrentState}
                        />
                      </Form.Item>
                    }
                    {
                      (currentState === 'created' || currentState === 'waiting') && isEdit &&
                      <Form.Item name="servant_id"
                        initialValue={data?.servant_id}
                        label="Назначить на исполнителя"
                        labelCol={{ span: 24 }}>
                        <Select options={allowedServants} />
                      </Form.Item>
                    }
                  </Form>
                </Col>
              </Row>
              <Row className="order-card__footer">
                <Col lg={8} md={12} xs={24}>
                  <Button type="primary" style={{ marginRight: 8 }} onClick={() => form.submit()}>
                    {isEdit ? "Сохранить" : "Создать"}
                  </Button>
                  <Button onClick={handleCancel}>
                    Отмена
                  </Button>
                </Col>
              </Row>
            </>
          )
        }
      </div>
    </div>
  );
};
