import { Button, Table, Tag, PageHeader } from "antd";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useRequest, useToggle } from "ahooks";
import history from "history/browser";

import { getOrdersList } from "@/api/order";
import { getCategoryName, setState } from "@/helpers";
import { useState } from "react";
import StateModal from "@/components/UI/StateModal";
import moment from "moment";

const { Column } = Table;

export default function MainPage(record: any) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState<number>(+(searchParams.get("page") ?? 1));
  const [isModal, { setRight: setIsModalRight, setLeft: setIsModalLeft }] = useToggle();
  const [modalData, setModalData] = useState<any>({});
  const [selectedOrder, setSelectedOrder] = useState<any>({});

  const { loading, data, run } = useRequest(getOrdersList);
  const categories = JSON.parse(sessionStorage.getItem("categories") || "[]");

  function handleOpenModal(event: any, id: number, state: string, data: any) {
    event.stopPropagation();
    setSelectedOrder({
      id,
      state,
    });

    if (!!data?.schema) {
      setIsModalRight();
      setModalData(data);
    } else {
      handleChangeState(data);
    }
  }

  function handleModalCancel() {
    setIsModalLeft();
  }

  function handleCreate() {
    navigate("/order/create");
  }

  function handleRowClick(record: any) {
    navigate(`/order/${record.id}`);
  }

  function handleTableChange(pagination: any, filters: any, sorter: any, extra: any) {

    if (extra?.action === "paginate") {
      setPage(pagination?.current ?? 1);
      history.replace({
        pathname: "/",
        search: `?page=${pagination?.current ?? 1}`
      });
    }
  }

  function handleChangeState(data: any) {
    setState(selectedOrder.id, selectedOrder.state, data)
      .finally(() => {
        run();
        setIsModalLeft();
      });
  }

  function handleCategoryFilter(value: number | string | boolean, record: any) {
    return record?.order_category_id === value
  }

  return (
    <div className="container">
      <PageHeader title="Заявки"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }}
        extra={[
          <Button type="primary" key={1} onClick={handleCreate}>Создать новую заявку</Button>
        ]}
      />

      <Table dataSource={data}
        rowKey={"id"}
        loading={loading}
        onChange={handleTableChange}
        pagination={{ current: page, showSizeChanger: false }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => handleRowClick(record)
          };
        }}
        className={"mb-16"}
        scroll={{ x: 800 }}
      >

        <Column title="Номер заявки" dataIndex="id" key="id" defaultSortOrder={"descend"}
          sortDirections={["ascend", "descend", "ascend"]} sorter={(a: any, b: any) => a.id - b.id} />
        <Column title="Статус" dataIndex="state_rec" key="id" align={"center"} render={(state) => (
          <Tag color={state.style}>
            {state.name}
          </Tag>
        )} />
        <Column
          title="Категория"
          dataIndex="order_category_id"
          key="id"
          filters={categories.map((c: any) => ({
            text: c.name,
            value: c.id
          }))}
          onFilter={handleCategoryFilter}
          render={(order_category_id) => (
            getCategoryName(order_category_id, categories)
          )} />
        <Column title="Дата заявки" dataIndex="created_at" key="created_at" render={(created_at) => (
          moment(created_at).format("DD.MM.YYYY")
        )} />
        <Column title="Контактное лицо" dataIndex="customer_name" key="id" render={(customer_name) => (
          customer_name?.raw || customer_name?.value
        )} />
        <Column title="Адрес" dataIndex="address" key="id" render={(address) => (
          address?.value
        )} />
        <Column
          title="Действия"
          key="id"
          align={"center"}
          render={(_, record: any) => (
            <>
              {
                record.permitted_events_config.map((c: any) => (
                  <Button type={"link"}
                    key={c.key}
                    onClick={(event) => handleOpenModal(event, record.id, c.key, c.event_form)}>
                    {c.name}
                  </Button>
                ))
              }
            </>
          )}
        />
      </Table>

      <StateModal open={isModal}
        modalData={modalData}
        handleOk={handleChangeState}
        onCancel={handleModalCancel} />
    </div>
  );
};
