export default function handlePushUnsubscription() {
  return navigator.serviceWorker.getRegistration()
    .then((registration) => {
      if(registration) {
        return registration.pushManager.getSubscription()
          .then((subscription) => {
            if (subscription) {
              const oldEndpoint = subscription.endpoint;
              return subscription.unsubscribe()
                .then(() => {
                  console.log('Successfully unsubscribed from push notifications.');
                  localStorage.setItem("oldEndpoint", oldEndpoint);
                })
            }
          });
      }
    });
}
