export default function getAddressValue(
  address: string,
  addressOptions: any[],
) {
  let result = {
    value: address,
  };

  const selectedAddress = addressOptions.length
    ? addressOptions.find((o: any) => o.value === address)
    : null;

  if (selectedAddress) {
    result = selectedAddress;
  }

  return result;
}
