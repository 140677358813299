import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, PageHeader, Row, Tag, Typography, Image } from "antd";
import React, { useCallback, useState } from "react";
import history from "history/browser";
import { useRequest, useToggle } from "ahooks";
import { getOrderDetails } from "@/api/order";
import Loader from "@/components/UI/Loader";
import { getCategoryName, getLocaleDate, setState } from "@/helpers";
import StateModal from "@/components/UI/StateModal";

const { Text } = Typography;

export default function OrderPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [isModal, { setRight: setIsModalRight, setLeft: setIsModalLeft }] = useToggle();
  const [modalData, setModalData] = useState<any>({});
  const [orderState, setOrderState] = useState<string>("");

  const { loading, data, run } = useRequest(() => getOrderDetails(params.id));

  const categories = JSON.parse(sessionStorage.getItem("categories") || "[]");

  function handleOpenModal(state: string, data: any) {
    setOrderState(state);
    if (!!data?.schema) {
      setIsModalRight();
      setModalData(data);
    } else {
      handleChangeOrderState(data);
    }
  }

  const handleChangeOrderState = useCallback((data: any) => {
    if (params.id) {
      setState(+params.id, orderState, data)
        .finally(() => {
          run();
          setIsModalLeft();
        });
    }
  }, [orderState, params.id, run, setIsModalLeft]);

  function handleModalCancel() {
    setIsModalLeft();
  }

  function handleCancel() {
    history.back();
  }

  function handleCreate() {
    navigate("/order/create");
  }

  function handleEdit() {
    navigate(`/order/edit/${params.id}`);
  }

  const currentServant = data?.allowed_servants.find(({ id }: { id: number }) => id === data.servant_id)

  return (
    <div className="container">
      <PageHeader title={`Заявка #${params.id}`}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0
        }}
        extra={[
          <Button type="primary" key={1} onClick={handleCreate}>Создать новую заявку</Button>
        ]} />
      <div className="order-card">
        {
          loading && <Loader />
        }
        {
          !loading && (
            <>
              <PageHeader title={data.address?.raw || data.address?.value?.value}
                onBack={handleCancel}
                subTitle={`#${params.id}`}
                tags={
                  <Dropdown
                    disabled={!data.permitted_events_config.length}
                    key="more"
                    menu={{
                      onClick: ({ key }) => handleOpenModal(key, data.permitted_events_config.find((c: any) => c.key === key).event_form),
                      items: data.permitted_events_config.map((c: any) => ({
                        key: c.key,
                        label: (
                          <Tag color={c.style}>{c.name}</Tag>
                        )
                      }))
                    }}
                  >
                    <Tag color={data.state_rec.style}>{data.state_rec.name}</Tag>
                  </Dropdown>
                }
                extra={
                  data.permitted_events_config.map((c: any) => (
                    <Button key={c.key}
                      onClick={() => handleOpenModal(c.key, c.event_form)}>
                      {c.name}
                    </Button>
                  ))
                }
              />
              <div className="order-card__body">
                <div className="mb-16">
                  <Text type="secondary">Дата размещения: </Text>
                  <Text>{getLocaleDate(data.created_at)}</Text>
                </div>
                <div className="mb-16">
                  <Text type="secondary">Категория: </Text>
                  <Text>{getCategoryName(data.order_category_id, categories)}</Text>
                </div>
                <div className="mb-16">
                  <Text type="secondary">Контактное лицо: </Text>
                  <Text>{data.customer_name?.raw || data.customer_name?.value}</Text>
                </div>
                <div className="mb-16">
                  <Text type="secondary">Контактный телефон: </Text>
                  <Text>{data.customer_phone}</Text>
                </div>
                <div className="mb-16">
                  <Text>{data.text}</Text>
                </div>
                <div className="mb-16">
                  {
                    !!currentServant?.name?.raw || currentServant?.name
                      ? <>
                        <Text type="secondary">Назначено: </Text>
                        <Text>{currentServant.name.raw || currentServant?.name}</Text>
                      </>
                      : <><Text type="secondary">Не назначено</Text></>
                  }

                </div>
                <Row gutter={[8, 8]} align={"stretch"} className="mb-16">
                  <Image.PreviewGroup>
                    {!!data.photos?.length &&
                      data.photos?.map((p: string) => (
                        <Col span={4} key={p}>
                          <Image
                            src={p}
                            className="img-cover"
                            rootClassName="image-wrapper"
                          />
                        </Col>
                      ))}
                  </Image.PreviewGroup>
                </Row>
                <Text type="secondary">Результат работы</Text>
                <div className="mb-16">
                  <Image.PreviewGroup>
                    {!!data?.result_photos?.length &&
                      data.result_photos?.map((p: string, idx: number) => (
                        <Col span={6} key={`${p}-${idx}`}>
                          <Image
                            src={p}
                            className="img-cover"
                            rootClassName="image-wrapper"
                          />
                        </Col>
                      ))}
                  </Image.PreviewGroup>
                </div>
                <div className="mb-16">
                  <Text type="secondary">Комментарий: </Text>
                  <Text className="block">{data?.result_comment}</Text>
                </div>
                
              </div>
              <div className="order-card__footer">
                <Button type="primary" onClick={handleEdit}>Редактировать заявку</Button>
              </div>
            </>
          )
        }
      </div>

      <StateModal open={isModal}
        modalData={modalData}
        handleOk={handleChangeOrderState}
        onCancel={handleModalCancel} />
    </div>
  );
};
