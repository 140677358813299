import {Form, Input, Modal, ModalProps, Upload} from "antd";
import React from "react";
import {CloseCircleFilled, PlusOutlined} from "@ant-design/icons";
import {getUploadLink, uploadPhoto} from "@/api/order";

interface StateModalProps extends ModalProps {
    modalData: any,
    handleOk: (form:any) => void,
}

function buildFieldsFromSchema(modalData: any): JSX.Element[] {
    const {schema} = modalData;
    if (schema?.type === "object") {
        const {properties} = schema;
        return Object.keys(properties).reduce((result: JSX.Element[], key: string) => {
            const field: { type: string, title?: string, items?: any } = properties[key];

            switch (field.type) {
                case "string":
                    result = [
                        ...result,
                        <Form.Item name={key}
                                   key={key}
                                   labelCol={{span: 24}}
                                   label={field.title}>
                            <Input placeholder={field.title}/>
                        </Form.Item>
                    ]
                    break;
                case "array":
                    result = [
                        ...result,
                        <Form.Item name={key} valuePropName={"file"} key={key}>
                            <Upload
                                listType="picture-card"
                                accept="capture=camera,image/*"
                                beforeUpload={() => false}
                                showUploadList={{
                                    showPreviewIcon: false,
                                    removeIcon: (
                                        <CloseCircleFilled style={{color: "#1890FF", fontSize: 24}}/>
                                    ),
                                }}
                            >
                                <PlusOutlined style={{fontSize: 32}}/>
                            </Upload>
                        </Form.Item>
                    ]
                    break;
            }

            return result;
        }, []);

    }

    return [];
}

export default function StateModal({
                                       open,
                                       handleOk,
                                       onCancel,
                                       modalData,
                                   }: StateModalProps) {
    const [form] = Form.useForm();

    function handleSubmit(values:any) {
        const { images } = values;
        const guid = crypto.randomUUID
            ? crypto.randomUUID()
            : new Date().getTime().toString(16);

        if (images && images?.fileList?.length) {
            Promise.all(
                images.fileList.map((f: any) => {
                    const { name, type } = f;
                    return getUploadLink(name, type, guid);
                })
            )
                .then((data: any[]) => {
                    const sendPhotos: string[] = data.map((res: any) => res.signedUrl);
                    return Promise.all(
                        sendPhotos.map((url: string, index: number) => {
                            const file = images.fileList[index];
                            return uploadPhoto(url, file.originFileObj, file.type);
                            
                        })
                    ).then(() => {
                        handleOk({
                            ...modalData,
                            form_data: {
                                ...values,
                                images: sendPhotos
                                
                            },
                        })
                    });
                });
        } else {
            handleOk({
                ...modalData,
                form_data: values,
            })
        }
    }

    function onOk() {
        form.submit();
    }

    return (
        <Modal open={open}
               onOk={onOk}
               onCancel={onCancel}
               okText="Сохранить"
               cancelText="Отменить"
               closable={false}>
            <Form form={form} onFinish={handleSubmit}>
                {
                    buildFieldsFromSchema(modalData).map((field: any) => (
                        field
                    ))
                }
            </Form>
        </Modal>
    )
}
